<template>
  <v-container>
    <div class="d-flex justify-center mt-8">
      <v-btn color="primary" @click="addTeamDialog = true">{{ $t('addTeam') }}</v-btn>
    </div>
    <add-team-dialog v-model="addTeamDialog" @team-added="loadTeams" />

    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :items="teams"
      :footer-props="footerProps"
      class="table"
      :hide-default-header="teams.length < 1"
      :hide-default-footer="teams.length < 1"
      :items-per-page="50"
      @click:row="openTeam"
    >
      <template #[`item.name`]="{ item }">
        <div class="full-height-td" :class="{ 'animated pulse': item.updated }">
          {{ item.name }}
        </div>
      </template>
      <template #[`item.profilePhotos`]="{ item }">
        <team-profile-photos :urls="item.profilePhotos" />
      </template>

      <template #[`item.memberCount`]="{ item }">
        <div :class="{ 'animated pulse': item.updated }">
          <v-icon class="pa-1" :class="{ 'animated pulse': item.updated }">fa-users</v-icon>
          {{ item.memberCount }}
        </div>
      </template>

      <template #[`item.managed`]="{ item }">
        <template v-if="item.managed">
          <v-icon :title="$t('managedTeamInfo')" class="pa-1">fa-fw fa-id-badge</v-icon>
          {{ $t('managed') }}
        </template>
      </template>

      <template #[`item.actions`]="{ item }">
        <template v-if="showActionText">
          <v-btn :disabled="item.managed" @click.stop="promptRemoveTeam(item)">
            <v-icon left>fa-trash</v-icon>
            {{ $t('remove') }}
          </v-btn>
        </template>

        <template v-else>
          <v-btn icon :disabled="item.managed" @click.stop="promptRemoveTeam(item)">
            <v-icon class="pa-1">fa-trash</v-icon>
          </v-btn>
        </template>
      </template>

      <template #no-data>
        <empty-state
          :title="$t('noTeams')"
          :message="$t('noTeamsMsg')"
          icon-src="@/assets/images/empty-states/teams.svg"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import auth from '@/util/auth';
import AddTeamDialog from '@/components/AddTeamDialog';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import TeamProfilePhotos from '@/components/TeamProfilePhotos';
import confirmDialog from '@/components/dialog/confirmDialog';
import EmptyState from '@/components/EmptyState';

export default {
  name: 'Teams',

  components: {
    AddTeamDialog,
    TeamProfilePhotos,
    EmptyState,
  },

  data: (self) => ({
    loading: null,
    addTeamDialog: null,
    teams: [],
    events: [],
    headers: [
      {
        value: 'id',
        sortable: true,
      },
      {
        value: 'profilePhotos',
        text: ' ',
        sortable: true,
      },
      {
        value: 'name',
        sortable: true,
      },
      {
        value: 'description',
        sortable: true,
        hide: true,
      },
      {
        value: 'memberCount',
        text: self.$t('members'),
        hide: true,
        sortable: true,
      },
      {
        value: 'managed',
        align: 'center',
      },
      {
        value: 'actions',
        align: 'end',
        text: self.$t('actions'),
      },
    ].map((header) => ({
      ...header,
      text: header.text || self.$t(header.value),
      sortable: header.sortable === true ? true : false,
    })),

    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    hasManagedTeams() {
      return this.teams.find((t) => t.managed);
    },

    computedHeaders() {
      return this.headers.filter((h) => {
        let show = true;
        if (h.value === 'managed') {
          show = show && this.hasManagedTeams;
        }

        if (h.hide) {
          show = show && !this.showExpand;
        }

        return show;
      });
    },

    showExpand() {
      return this.$vuetify.breakpoint.mdAndDown && this.$vuetify.breakpoint.width >= 600;
    },

    showActionText() {
      return this.$vuetify.breakpoint.width < 600;
    },
  },

  mounted() {
    this.loadTeams();
    this.subscribeToSignalREvents(auth.getCustomerId());
  },
  beforeDestroy() {
    this.unregisterSignalR();
  },
  methods: {
    async loadTeams() {
      this.loading = true;

      try {
        const res = await api.getTeams(3);
        this.teams = res.teams;
      } catch (error) {
        errorMessage(this.$t('getTeamsError'), this.$t('getTeamsErrorMsg'), error);
      }

      this.loading = false;
    },
    async updateTeams(teamId) {
      this.loading = true;

      try {
        const res = await api.getTeams(3);
        this.teams = res.teams;
        if (teamId == null || teamId === undefined) {
          this.loading = false;
          return;
        }
        let changedTeam = this.teams.find((t) => t.id.toString() === teamId);
        if (changedTeam === null || changedTeam === undefined) {
          this.loading = false;
          return;
        }
        changedTeam.updated = true;
        setTimeout(() => {
          //Remove animation class
          changedTeam.updated = false;
        }, 1500);
      } catch (error) {
        errorMessage(this.$t('getTeamsError'), this.$t('getTeamsErrorMsg'), error);
      }

      this.loading = false;
    },

    async promptRemoveTeam(team) {
      const confirmed = await confirmDialog(
        this.$t('removeTeam'),
        this.$t('removeTeamMsg', null, {
          teamName: team.name,
        }),
        this.$t('cancel'),
        this.$t('remove'),
      ).open();

      if (confirmed) {
        try {
          await api.deleteTeam(team.id);
          this.loadTeams();
        } catch (error) {
          errorMessage(this.$t('removeTeamError'), this.$t('removeTeamErrorMsg'), error);
        }
      }
    },

    openTeam(team) {
      this.$router.push('/company/teams/' + team.id);
    },
    subscribeToSignalREvents(customerId) {
      this.events.forEach((event) => this.$signalR.off(event));
      const event = `teamsUpdated:${customerId}`;
      this.events.push(event);
      this.$signalR.on(event, (data) => {
        if (customerId == event.split(':')[1]) {
          this.updateTeams(data.teamId);
        }
      });
    },
    unregisterSignalR() {
      this.events.forEach((event) => this.$signalR.off(event));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';
.table ::v-deep tbody tr:not(.v-data-table__empty-wrapper) {
  cursor: pointer;
}
.full-height-td {
  height: 90%;
  display: grid;
  justify-content: left;
  align-items: center;
}
.animated {
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    background-color: white;
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
    background-color: $colorBluePrimary;
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    background-color: white;
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    background-color: white;
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
    background-color: $colorBluePrimary;
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    background-color: white;
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
</style>
