<template>
  <v-dialog v-model="addTeamDialog" max-width="500px">
    <v-card>
      <v-card-title>{{ $t('addTeam') }}</v-card-title>

      <v-card-text>
        <v-form ref="addTeamForm" v-model="addTeamFormValid">
          <v-text-field
            v-model="name"
            :counter="100"
            :rules="nameRules"
            :label="$t('teamName')"
            prepend-icon="fa-tag"
          />

          <v-textarea
            v-model="description"
            :counter="300"
            :rules="descriptionRules"
            :label="$t('description')"
            prepend-icon="fa-info-circle"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelAddTeam">{{ $t('cancel') }}</v-btn>
        <v-btn :disabled="!addTeamFormValid" color="primary" @click="addTeam">{{
          $t('add')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/util/api';
import { nameRules, descriptionRules } from '@/consts/teamRules';

export default {
  name: 'AddTeamDialog',

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },

    members: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    addTeamDialog: false,
    addTeamFormValid: false,

    name: '',
    description: '',

    nameRules: nameRules(),
    descriptionRules: descriptionRules(),
  }),

  watch: {
    addTeamDialog() {
      this.onChange();
    },

    value() {
      this.addTeamDialog = this.value;
    },
  },

  created() {
    this.addTeamDialog = this.value;
  },

  methods: {
    reset() {
      this.$refs.addTeamForm.reset();
    },

    cancelAddTeam() {
      this.reset();
      this.addTeamDialog = false;
    },

    addTeam() {
      const newTeam = {
        name: this.name,
        description: this.description,
      };

      api
        .createTeam(newTeam)
        .then((newTeamId) => {
          let newlyCreatedTeam = { ...newTeam };
          newlyCreatedTeam.teamId = newTeamId;
          this.$emit('team-added', newlyCreatedTeam);
          this.reset();
          this.addTeamDialog = false;
        })
        .catch(() => {
          // TODO: Handle error
        });
    },

    onChange() {
      this.$emit('input', this.addTeamDialog);
    },
  },
};
</script>

<style></style>
