<template>
  <div class="wrapper">
    <profile-photo
      v-for="(url, index) in sortedUrls"
      :key="index"
      :url="url"
      :clickable="false"
      class="team-profile-photo"
      size="30px"
      :style="'left: ' + 10 * index + 'px'"
    />
  </div>
</template>

<script>
import ProfilePhoto from './ProfilePhoto.vue';

export default {
  name: 'TeamProfilePhotos',

  components: {
    ProfilePhoto,
  },

  props: {
    urls: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sortedUrls: function () {
      // Sort urls so that non-empty urls are last (most visible)
      return [...this.urls].sort((a, b) => {
        if (a === null || a === '') return -1;
        if (b === null || b === '') return 1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-width: 30px;
}

.team-profile-photo {
  position: absolute;
  top: 5px;
}
</style>
